<template>
    <div class="daily-growth-widget">
        <div v-if="chartOnly">
            <chart v-if="chartOptions.series[0].data.length > 0" :chartOptions="chartOptions" :chartID="chartID"></chart>
        </div>
        <div v-else>
            <highcharts :options="chartOptions" :style="`height: ${height};`"></highcharts>
        </div>
        
    </div>
</template>

<script>
import { compareAsc, format, subBusinessDays } from 'date-fns';
import { map, clone } from 'lodash';
import { exportCsv } from '@/lib/Exporting';
import chart from '@/widgets/highchart'
import { getAccountSummaryRange } from '@/lib/Helpers'

export default {
    name: 'daily-growth',
    data() {
        let exportingOptions = {
            filename: "DailyGrowth",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Daily Growth"}
            },
            
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        };
        return {
            chartOptions: {
                exporting: exportingOptions,
                chart: {
                    zoomType: 'x'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    min: -5,
                    max: 5,
                    title: {
                        text: 'Growth'
                    },
                    labels: {
                        overflow: 'justify',
                        formatter: function () {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    valueDecimals: 2
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                series: [
                    {
                        name: 'Growth',
                        type: 'area',
                        data: []
                    }
                ]
            },
            hasSetupListeners: false,
            interval:null,
            firstUpdate: true
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: '250px'
        },
        chartOnly:{
            type: Boolean,
            default: false
        },
        dateEnd: {
            type: Date,
            default: null
        },
        dateStart: {
            type: Date,
            default: null
        },
        chartID:{
            type: String,
            default:"0"
        }
    },
    components:{
        chart
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('dailygrowth', this.onDailyGrowth);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('dailygrowth', this.onDailyGrowth);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'DailyGrowth',
                args: {}
            });
        },
        onDailyGrowth(event) {
            if(this.firstUpdate) {
                this.firstUpdate = false;
            } else {
                return;
            }

            let dates = Object.keys(event.response).map(function(date) {
                return new Date(date);
            }).sort(compareAsc);

            let formattedDates = {};
            for(let date of dates) {
                formattedDates[`${format(date, 'yyyy-MM-dd')}T00:00:00`] = date;
            }

            let row = {
                name: 'Growth',
                type: 'area',
                data: []
            };

            let min = -5;
            let max = 5;

            let first = true;
            for(let date in formattedDates) {
                if(first) {
                    let firstDate = subBusinessDays(formattedDates[date], 1);
                    row.data.push([Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate()), 0]);
                    first = false;
                }

                let value = parseFloat(event.response[date]) * 100;

                let ceilValue = Math.ceil(value);
                let floorValue = Math.floor(value);

                if(ceilValue > max) {
                    max = ceilValue;
                } else if(ceilValue === max) {
                    max += 1;
                }

                if(floorValue < min) {
                    min = floorValue;
                } else if(floorValue === min) {
                    min -= 1;
                }

                row.data.push([Date.UTC(formattedDates[date].getFullYear(), formattedDates[date].getMonth(), formattedDates[date].getDate()), value]);
            }

            this.chartOptions.series = [row];
        }
    }
}
</script>