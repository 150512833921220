<template>
    <div class="open-margins-widget">
        <div class="col-12 d-flex align-items-center justify-content-end" style="padding-top: 13px;">
            <b-form-select id="execution-tolerance-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
        </div>
        <highcharts :options="chartOptions" :style="`height: ${height};`"></highcharts>
    </div>
</template>

<script>
import { numberFormat } from 'highcharts';
import { getColor } from '@/lib/ColorLookup';

export default {
    name: 'open-margins',
    data() {
        let exportingOptions = {
            filename: "Margins",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Open Margins"}
            },
            menuItemDefinitions: {
                // Custom context menu item
                exportCsv: {
                    onclick: function(){
                        let headers = {};

                        for(let column of that.tableOptions.columns) {
                            headers[column.key] = column.label;
                        }

                        let items = map(that.tableOptions.items, clone);
                        for(let item of items) {
                            item.date = format(item.date, 'yyyy-MM-dd HH:mm:ss');
                        }

                        exportCsv(headers, items, 'Breakdowns');
                    },
                    text: 'Download CSV file'
                },
            },
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG", "exportCsv"]
                }
            },
            fallbackToExportServer: false,
        };
        return {
            chartOptions: {
                exporting: exportingOptions,
                chart: {
                    type: 'bar'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    }
                },
                yAxis: {
                    min: 0,
                    max: 5,
                    title: {
                        text: 'Margin'
                    },
                    labels: {
                        overflow: 'justify',
                        formatter: function() {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    formatter: function() {
                        return '<b>' + this.series.name + '</b>: ' + numberFormat(this.y, 2) + '%';
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return '<b>' + numberFormat(this.y, 2) + '%</b>';
                            }
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                series: []
            },
            filterOptions: {
                type: [
                    {
                        value: 'Market',
                        text: 'Market'
                    },
                    {
                        value: 'Bucket',
                        text: 'Bucket'
                    },
                    {
                        value: 'Factor',
                        text: 'Factor'
                    }
                ]
            },
            type: 'Market'
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: '400px'
        }
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('openmargin', this.onOpenMargins);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('openmargin', this.onOpenMargins);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                accountName: account,
                request: 'Openmargin',
                args: {
                    filterType: this.type
                }
            });
        },
        onOpenMargins(event) {
            let max = 5;

            let series = [];
            for(let key in event.response) {
                let value = event.response[key] * 100;

                series.push({
                    name: key,
                    data: [value],
                    color: getColor(key)
                });

                let ceilValue = Math.ceil(value);

                if(ceilValue > max) {
                    max = ceilValue;
                } else if(ceilValue === max) {
                    max += 1;
                }
            }

            if(max !== this.chartOptions.yAxis.max) {
                this.chartOptions.yAxis.max = max;
            }

            this.chartOptions.series = series;
        }
    }
}
</script>