<template>
    <div class="daily-drawdown-widget">
        <div v-if="chartOnly">
            <chart v-if="chartOptions.series[0].data.length > 0" :chartOptions="chartOptions" :chartID="chartID"></chart>
        </div>
        <div v-else>
            <highcharts :options="chartOptions" :style="`height: ${height};`"></highcharts>
        </div>
        
    </div>
</template>

<script>
import { format, compareAsc, subBusinessDays } from 'date-fns';
import { map, clone } from 'lodash';
import { exportCsv } from '@/lib/Exporting';
import chart from '@/widgets/highchart'
import { getAccountSummaryRange } from '@/lib/Helpers'

export default {
    name: 'daily-drawdown',
    data() {
        let exportingOptions = {
            filename: "DailyDrawdown",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                },
                title: {text:"Daily Drawdown"}
            },
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF","downloadSVG","downloadPNG"],
                }
            },
            navigation: {
                buttonOptions: {
                    verticalAlign: 'bottom',
                }
            },
            fallbackToExportServer: false,
        };
        return {
            chartOptions: {
                exporting: exportingOptions,
                chart: {
                    zoomType: 'x'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    min: -5,
                    max: 5,
                    title: {
                        text: 'Drawdown'
                    },
                    labels: {
                        overflow: 'justify',
                        formatter: function () {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    valueDecimals: 2
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                series: [
                    {
                        name: 'Drawdown',
                        data: [],
                        type: 'area',
                        color: '#EC7C7C'
                    }
                ]
            },
            hasSetupListeners: false,
            firstUpdate: true
        };
    },
    props: {
        height: {
            type: String,
            default: '250px'
        },
        account: {
            type: String,
            default: ''
        },
        chartOnly:{
            type: Boolean,
            default: false
        },
        dateEnd: {
            type: Date,
            default: null
        },
        dateStart: {
            type: Date,
            default: null
        },
        chartID:{
            type: String,
            default:"0"
        }
    },
    components:{
        chart
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('dailydrawdown', this.onDailyDrawdown);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('dailydrawdown', this.onDailyDrawdown);

                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'DailyDrawdown',
                args: {}
            });
        },
        onDailyDrawdown(event) {
            if(this.firstUpdate) {
                this.firstUpdate = false;
            } else {
                return;
            }

            let row = {
                name: 'Drawdown',
                data: [],
                type: 'area',
                color: '#EC7C7C'
            };

            let dates = Object.keys(event.response).map(function(date) {
                return new Date(date);
            }).sort(compareAsc);

            let min = -5;
            let max = 5;

            let first = true;
            for(let date of dates) {
                if(first) {
                    let firstDate = subBusinessDays(date, 1);
                    row.data.push([Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate()), 0]);
                    first = false;
                }

                let utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

                let formattedDate = `${format(date, 'yyyy-MM-dd')}T00:00:00`;

                let value = parseFloat(event.response[formattedDate]) * 100;

                let ceilValue = Math.ceil(value);
                let floorValue = Math.floor(value);

                if(ceilValue > max) {
                    max = ceilValue;
                } else if(ceilValue === max) {
                    max += 1;
                }

                if(floorValue < min) {
                    min = floorValue;
                } else if(floorValue === min) {
                    min -= 1;
                }

                row.data.push([utc, value]);
            }

            this.chartOptions.series = [row];
        }
    }
}
</script>