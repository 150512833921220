<template>
    <div class="execution-tolerance-widget">
        <div v-if="chartOnly" class="col-12">
            <chart  @getSVG="getSVG" v-if="chartOptions.series.length > 0"  :chartOptions="chartOptions" :height="height" :chartID="chartID"></chart>
        </div>
        <div v-else-if="showChart">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" @click="toggleChartSeries">
                    <template v-if="allChartSeriesHidden">Show all series</template>
                    <template v-else>Hide all series</template>
                </b-button>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <highcharts :options="chartOptions" :style="`height: ${height};`"></highcharts>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
                <b-form inline @submit.prevent>
                    <label class="mr-sm-2" for="execution-tolerance-type-filter">Type:</label>
                    <b-form-select id="execution-tolerance-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
                    <label class="mr-sm-2" for="execution-tolerance-notation-filter">Notation:</label>
                    <b-form-select id="execution-tolerance-notation-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="notation" :options="filterOptions.notation" @change="onNotationChange"></b-form-select>
                    <label class="mr-sm-2" for="execution-tolerance-time-period">Time Period:</label>
                    <b-form-select id="execution-tolerance-time-period" class="mb-2 mr-sm-2 mb-sm-0" v-model="timePeriod" :options="filterOptions.timePeriod" @change="requests"></b-form-select>
                </b-form>
                <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2" :ranges="dateRanges">
                    <template v-slot:input="picker" style="min-width: 350px;">
                        <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                        <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                    </template>
                </date-range-picker>
            </div>
        </div>
        </div>
        <b-table :items="items" :fields="columns" responsive class="text-center" show-empty v-if="showTable">
            <template slot="empty">
                No results found

            </template>
            <template slot="bottom-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <template v-if="field.key === 'marketStrategy'">Total</template>
                    <template v-else>
                        <template v-if="notation === 'Percentage'">
                        <span :class="{'negative-tolerance': sum(field.key) < 0, 'positive-tolerance': sum(field.key) > 0}">{{sum(field.key) | number(3, true)}}</span>
                    </template>
                    <template v-else-if="notation === 'Size'">
                        <span :class="{'negative-tolerance': sum(field.key) < 0, 'positive-tolerance': sum(field.key) > 0}">{{sum(field.key) | number(0)}}</span>
                    </template>
                    <template v-else-if="notation === 'Cash'">
                        <span :class="{'negative-tolerance': sum(field.key) < 0, 'positive-tolerance': sum(field.key) > 0}">{{sum(field.key) | number | currency}}</span>
                    </template>
                    <template v-else-if="notation === 'Tolerance'">
                        <span :class="{'negative-tolerance': sum(field.key) < 0, 'positive-tolerance': sum(field.key) > 0}">{{sum(field.key) | number}}</span>
                    </template>
                    <template v-else>
                        <span :class="{'negative-tolerance': sum(field.key) < 0, 'positive-tolerance': sum(field.key) > 0}">{{sum(field.key)}}</span>
                    </template>
                    </template>
                </td>
            </template>
            <template v-slot:cell()="data">
                <template v-if="data.field.key === 'marketStrategy'">
                    {{data.value}}
                </template>
                <template v-else>
                    <span v-if="parseFloat(data.value) === 0">-</span>
                    <span v-else-if="parseFloat(data.value) > 0" style="color: green;">
                        <template v-if="notation === 'Percentage'">
                            {{data.value | number(3, true)}}
                        </template>
                        <template v-else-if="notation === 'Size'">
                            {{data.value | number(0)}}
                        </template>
                        <template v-else-if="notation === 'Cash'">
                            {{data.value | number | currency}}
                        </template>
                        <template v-else-if="notation === 'Tolerance'">
                            {{data.value | number}}
                        </template>
                        <template v-else>
                            {{data.value}}
                        </template>
                    </span>
                    <span v-else-if="parseFloat(data.value) < 0" style="color: red;">
                        <template v-if="notation === 'Percentage'">
                            {{data.value | number(3, true)}}
                        </template>
                        <template v-else-if="notation === 'Size'">
                            {{data.value | number(0)}}
                        </template>
                        <template v-else-if="notation === 'Cash'">
                            {{data.value | number | currency}}
                        </template>
                        <template v-else-if="notation === 'Tolerance'">
                            {{data.value | number}}
                        </template>
                        <template v-else>
                            {{data.value}}
                        </template>
                    </span>
                    <span v-else>
                        {{data.value}}
                    </span>
                </template>
            </template>
        </b-table>
    </div>
</template>

<script>
import { startOfMonth, endOfMonth, format, compareAsc, subBusinessDays, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import DateRangePicker from 'vue-daterange-picker-light';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { map, clone } from 'lodash';
import { exportCsv } from '@/lib/Exporting';
import chart from '@/widgets/highchart.vue'
import { objectEach } from 'highcharts';

export default {
    name: 'execution-tolerance',
    data() {
        let date = new Date();

        let that = this;

        let exportingOptions = {
            filename: "ExecutionTolerance",
            sourceWidth: "1500",
            sourceHeight: "500",
            chartOptions: { // specific options for the exported image
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: false,
                        }
                    },
                },
                chart: {
                        backgroundColor:"white",
                        
                },
                title: {text:"Execution Tolerance"},
            },
            tableOptions: {//This was added in to change the table from adding weekly results to daily results.
                
                timePeriod: "Daily",
            },
           
            buttons:{
                contextButton:{
                    menuItems:["viewFullscreen", "printChart", "separator", "downloadPDF",/*"downloadSVG"*/,"downloadPNG"]
                }
            },
            fallbackToExportServer: false,
        };

        return {
            dateRange: {
                startDate: startOfMonth(date),
                endDate: endOfMonth(date)
            },
            type: 'Market',
            notation: 'Percentage',
            timePeriod: 'Daily',
            filterOptions: {
                type: [
                    {
                        value: 'Market',
                        text: 'Market'
                    },
                    {
                        value: 'Bucket',
                        text: 'Bucket'
                    },
                    {
                        value: 'Factor',
                        text: 'Factor'
                    }
                ],
                notation: [
                    {
                        value: 'Percentage',
                        text: 'Percentage'
                    },
                    {
                        value: 'Tolerance',
                        text: 'Tolerance'
                    },
                    {
                        value: 'Size',
                        text: 'Size'
                    },
                    {
                        value: 'Cash',
                        text: 'Cash'
                    }
                ],
                timePeriod: [
                    {
                        value: 'Monthly',
                        text: 'Monthly'
                    },
                    {
                        value: 'Weekly',
                        text: 'Weekly'
                    },
                    {
                        value: 'Daily',
                        text: 'Daily'
                    }
                ]
            },
            chartOptions: {
                exporting:exportingOptions,
                chart: {
                    zoomType: 'x'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    title: {
                        text: 'Tolerance'
                    },
                    labels: {
                        overflow: 'justify',
                        suffix: "%",
                       formatter: function(){
                           return  this.value + that.chartOptions.yAxis.labels.suffix
                       }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    valueDecimals: 2,
                    valuePrefix: ""
                },
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    }
                },
                series: []
            },
            columns: [],
            items: [],
            hasSetupListeners: false,
            allChartSeriesHidden: false,
            dateRanges: {
                'This Week': [startOfWeek(date), endOfWeek(date)],
                'This Month': [startOfMonth(date), endOfMonth(date)],
                'Last Month': [startOfMonth(addMonths(date, -1)), endOfMonth(addMonths(date, -1))],
                'This Quarter': [startOfQuarter(date), endOfQuarter(date)],
                'Last Quarter': [startOfQuarter(addQuarters(date, -1)), endOfQuarter(addQuarters(date, -1))],
                'This Year': [startOfYear(date), endOfYear(date)],
                'Last Year': [startOfYear(addYears(date, -1)), endOfYear(addYears(date, -1))],
                'All Time': [new Date('2015-12-01'), endOfYear(date)]
            }
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        },
        showTable: {
            type: Boolean,
            default: true
        },
        showChart: {
            type: Boolean,
            default: true
        },
        height: {
            type: String,
            default: '400px'
        },
        chartOnly:{
            type: Boolean,
            default: false
        },
        dateEnd: {
            type: Date,
            default: null
        },
        dateStart: {
            type: Date,
            default: null
        },
        notationType:{
            type: String,
            default: null
        },
        chartID:{
            type: String,
            default:"0"
        },
        ID:{
            type:Number,
            default:null
        }
    },
    components: {
        DateRangePicker,
        chart
    },
    mounted() {
        if(this.notationType != null){
            this.notation = this.notationType
            this.onNotationChange();
        }else{
            this.requests();
        }
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('executiontolerance', this.onExecutionTolerance);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('executiontolerance', this.onExecutionTolerance);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            let showChart = true
            if((this.showChart != true && this.chartOnly != true)){
                showChart = false
            }
            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'ExecutionTolerance',
                args: {
                    startDate: this.dateStart == null ? format(this.dateRange.startDate, 'yyyy-MM-dd') : format(this.dateStart, 'yyyy-MM-dd'),
                    endDate: this.dateEnd == null ? format(this.dateRange.endDate, 'yyyy-MM-dd') : format(this.dateEnd, 'yyyy-MM-dd'),
                    filterType: this.type,
                    notationType: this.notation,
                    reason: 'null',
                    timePeriod: this.timePeriod,
                    showChart:showChart
                }
            });
        },
        onExecutionTolerance(event) {
            if(this.ID != null && event.request.args.notationType != this.notationType){
                return;
            }

            this.columns = [];

            let dates = Object.keys(event.response).map(function(date) {
                return new Date(date);
            }).sort(compareAsc);

            let formattedDates = {};
            for(let date of dates) {
                let formattedDate = format(date, 'yyyy-MM-dd');

                formattedDates[formattedDate] = `${formattedDate}T00:00:00`;
            }

            let columns = [
                {
                    key: 'marketStrategy',
                    label: this.type,
                    sortable: true
                }
            ];

            for(let date in formattedDates) {
                columns.push({
                    key: date,
                    label: date,
                    sortable: true
                });
            }

            columns.push({ //TOTAL COLUMN INFO
                key: 'total',
                label: 'Total',
                sortable: true
            });

            let marketStrategies = [];
            for(let date in formattedDates) {
                for(let marketStrategy in event.response[formattedDates[date]]) {
                    let market = marketStrategy
                    if(market.toLowerCase() == "stat"){
                        //change stat name
                        event.response[formattedDates[date]]["Trend 2.0"] = event.response[formattedDates[date]]["Stat"]
                        delete event.response[formattedDates[date]]["Stat"]
                        market = "Trend 2.0"
                    }
                    if(marketStrategies.indexOf(market) === -1) {
                        marketStrategies.push(market);
                    }
                }
            }

            marketStrategies.sort();

            let rows = [];
            let allSeries = [];
            let minTotal = this.chartOptions.yAxis.min;
            let maxTotal = this.chartOptions.yAxis.max;
            let added = false;
            for(let marketStrategy of marketStrategies) {
                let row = {
                    marketStrategy: marketStrategy
                };
                
                let series = {
                    name: marketStrategy,
                    data: [],
                    color: getColor(marketStrategy),
                    visible: true
                };

                let total = 0;
                let first = true;
                for(let date in formattedDates) {
                    if(event.response[formattedDates[date]].hasOwnProperty(marketStrategy)) {
                        let parsedDate = new Date(date);

                        if(first) {
                            let firstDate = subBusinessDays(parsedDate, 1);
                            series.data.push([Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate()), 0]);
                            first = false;
                        }

                        let value = parseFloat(event.response[formattedDates[date]][marketStrategy]);

                        total += value;
                        if(total < minTotal) {
                            minTotal = total
                        }
                        if(total > maxTotal) {
                            maxTotal = total;
                        }

                        if(this.notation == "Tolerance"){
                            value *= 100
                        }

                        row[date] = value;
                        
                        let totalValue = parseFloat(total);
                        if(this.notation === 'Percentage') {
                            totalValue *= 100;
                        }
                        //set label prefix
                        if (this.notation === 'Cash'){
                            this.chartOptions.yAxis.labels.prefix = this.$options.filters.currency(0,false,true);
                        }else{
                            this.chartOptions.yAxis.labels.prefix = "";
                        }

                        series.data.push([Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()), totalValue]);
                    }
                }
                
                if(this.notation == "Tolerance"){
                    total *= 100
                }
                row.total = total;
                rows.push(row);
                allSeries.push(series);
            }

            // if(minTotal !== this.chartOptions.yAxis.min) {
            //     this.chartOptions.yAxis.min = minTotal;
            // }

            // if(maxTotal !== this.chartOptions.yAxis.max) {
            //     this.chartOptions.yAxis.max = maxTotal;
            // }

            if(event.request.args.showChart == "true"){
                this.chartOptions.series = allSeries;
            }
            if(this.showTable){
                this.columns = columns;
                this.items = rows;
            }
            
            
            //rows.map((x)=>{Object.keys(x).forEach((key)=>{x[key] = isNaN(Number(x[key])) ? x[key] : (x[key]*100).toFixed(2)});return x;})
            if(this.ID != null && this.showTable && event.request.args.showChart == "false"){
                this.$emit("getData", rows , this.ID);
            }   
            
        },
        sum(field) {
            return this.items.reduce(function(previousValue, item) {
                return previousValue + parseFloat(item[field]);
            }, 0.0);
        },
        onNotationChange(event) {
            this.chartOptions.tooltip.valuePrefix = "";
            this.chartOptions.tooltip.valueSuffix = '';
            this.chartOptions.yAxis.labels.formatter = (val)=>{
                return  val.value + this.chartOptions.yAxis.labels.suffix
            }
            this.chartOptions.tooltip.formatter = undefined
            if(this.notation === 'Percentage') {
                this.chartOptions.tooltip.valueSuffix = '%';
                this.chartOptions.tooltip.valueDecimals = 2;
            }else if(this.notation === 'Cash'){
                this.chartOptions.tooltip.valueDecimals = 2;
                this.chartOptions.tooltip.valuePrefix = this.$options.filters.currency(0,false,true);   
            }else if(this.notation === "Tolerance"){
                this.chartOptions.tooltip.valueSuffix = '%';
                this.chartOptions.tooltip.valueDecimals = 2;
                this.chartOptions.tooltip.formatter = function(){
                    return `<span style="color:${this.color}">●</span> ${this.series.name} <b>${(Number(this.y)*100).toFixed(2)}%</b><br/>`
                }
                this.chartOptions.yAxis.labels.formatter = (val)=>{
                    return (val.value*100).toFixed(2) + "%"
                }
            }else {
                this.chartOptions.tooltip.valueDecimals = 0;
            }

            this.requests();
        },
        toggleChartSeries() {
            if(this.allChartSeriesHidden) {
                this.allChartSeriesHidden = false;
                for(let series of this.chartOptions.series) {
                    series.visible = true;
                }
            } else {
                this.allChartSeriesHidden = true;
                for(let series of this.chartOptions.series) {
                    series.visible = false;
                }
            }
        },
        getSVG(svg){
            if(this.showChart){
                this.$emit("getSVG", svg , this.ID);
            }
            
        },
        onExport() {
            let headers = {};

            for(let column of this.columns) {
                headers[column.key] = column.label;
            }

            exportCsv(headers, map(this.items, clone), 'ExecutionTolerance');
        }
    }
}
</script>

<style lang="scss">
.positive-tolerance {
    color: green;
}

.negative-tolerance {
    color: red;
}
</style>