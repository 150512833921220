<template>
    <div id="page-wrapper">
        <top-header></top-header>
        <side-navbar></side-navbar>
        <main-content title="Charts" >
            <div class="col-12 d-flex align-items-center justify-content-end mb-2">
                <b-button variant="light" class="mr-sm-2" @click="onExport">
                Export All
                </b-button>
                <date-range-picker v-model="dateRange" opens="left" class="mr-2">
                        <template v-slot:input="picker" style="min-width: 350px;">
                            <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                            <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                        </template>
                </date-range-picker>
            </div>
            <div class="col-md-6 float-left">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Daily Growth</h4>
                        </div>
                        <div class="card-body">
                            <!-- <daily-growth chartID="3" :key="String(dateRange.startDate)" v-bind="{dateStart: dateRange.startDate, dateEnd:dateRange.endDate, chartOnly:true}"></daily-growth> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 float-left">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Daily Drawdown</h4>
                        </div>
                        <div class="card-body">
                            <!-- <daily-drawdown chartID="4" :key="String(dateRange.startDate)" v-bind="{dateStart: dateRange.startDate, dateEnd:dateRange.endDate, chartOnly:true}"></daily-drawdown> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 float-left">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Leverage (floating)</h4>
                        </div>
                        <div class="card-body">
                            <leverage></leverage>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 float-left">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Margin (floating)</h4>
                        </div>
                        <div class="card-body">
                            <margin></margin>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 float-left">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Return Breakdown (Total)</h4>
                        </div>
                        <div class="card-body">
                            <breakdowns :chartID="exportData[2].id" :key="String(dateRange.startDate)" v-bind="{dateStart: dateRange.startDate, dateEnd:dateRange.endDate, showTable:false, showChart:true, chartOnly:true}"></breakdowns>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 float-left">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Live vs Replay</h4>
                        </div>
                        <div class="card-body">
                            <live-replay :chartID="exportData[1].id" :key="String(dateRange.startDate)" v-bind="{dateStart: dateRange.startDate, dateEnd:dateRange.endDate, chartOnly:true}"></live-replay>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 float-left">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Execution Tolerance</h4>
                        </div>
                        <div class="card-body">
                            <execution-tolerance :chartID="exportData[0].id" :key="String(dateRange.startDate)" notationType="Cash" v-bind="{dateStart: dateRange.startDate, dateEnd:dateRange.endDate, chartOnly:true}"></execution-tolerance>
                        </div>
                    </div>
                </div>
                
        </main-content>
    </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import SideNavbar from '@/components/SideNavbar';
import MainContent from '@/components/MainContent';
import leverage from '@/widgets/OpenLeverages';
import margin from '@/widgets/OpenMargins';
import Breakdowns from '@/widgets/Breakdowns';
import DailyGrowth from '@/widgets/DailyGrowth';
import DailyDrawdown from '@/widgets/DailyDrawdown';
import LiveReplay from '@/widgets/LiveVsReplay.vue';
import ExecutionTolerance from '@/widgets/ExecutionTolerance.vue';

import DateRangePicker from 'vue-daterange-picker-light';
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv } from '@/lib/Exporting';
import chart from '@/widgets/highchart'
import {exportChartWithText} from '@/lib/Exporting'

export default {
    data() {
        return {
            dateRange: {
                startDate: new Date(`${new Date(Date.now()).getFullYear().toString()}-01-01`),
                endDate: new Date(Date.now())
            },
            exportData:[
                {id:"0",title:"Tolerance",description:"",scaleX:0.9,scaleY:0.9,split:false,enabled:true},
                {id:"1",title:"Live Vs Replay",description:"",scaleX:0.9,scaleY:0.9,split:false,enabled:true},
                {id:"2",title:"Breakdowns",description:"",scaleX:0.9,scaleY:0.9,split:false,enabled:true}
            ]

        };
    },
    components: {
        TopHeader,
        SideNavbar,
        MainContent,
        leverage,
        margin,
        Breakdowns,
        DailyGrowth,
        DailyDrawdown,
        DateRangePicker,
        LiveReplay,
        ExecutionTolerance,
        chart
    },
    methods:{
        onExport(){
            exportChartWithText(this.$store.getters.getCharts(),this.exportData, {
                    type: 'application/pdf',
                    filename: 'report'
            });
        }
    }
}
</script>